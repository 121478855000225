import React, { useState } from 'react';
import './HomePage.css'; // We'll use the same CSS file for now

function ServicesPage() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    businessDescription: '',
    helpOptions: {
      accountManagement: false,
      ppc: false,
      listingRanking: false,
      accountAudit: false
    },
    monthlySales: '',
    comment: ''
  });

  const services = [
    {
      title: "Done For You Amazon Management",
      description: "Amazon account set-up, case management, brand protection, product listing and optimization, sales and traffic reporting"
    },
    {
      title: "Amazon PPC Advertising",
      description: "Grow Amazon revenue through paid advertising while saving time. Clear reporting detailing traffic and conversion"
    },
    {
      title: "Amazon Strategy Consulting",
      description: "Is your Company searching for the strategy and processes that can drive their business forward on Amazon? We can help achieve your goals"
    },
    {
      title: "Amazon SEO",
      description: "Amazon success is dependent upon customers finding your products. Our strategic process for optimizing your listings will drive organic ranking and therefore increase sales and revenue"
    },
    {
      title: "Content Creation",
      description: "Engaging content will get customers to click on your listing. Relevant information on your product pages along with compelling sales images will increase conversion on your Amazon product detail page"
    }
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        helpOptions: {
          ...prevState.helpOptions,
          [name]: checked
        }
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Here you would typically send the form data to a server
    setIsPopupOpen(false);
  };

  return (
    <div className="services-page">
      <h1>Our Services</h1>
      <div className="services-scroll-container">
        <div className="services-row">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      
      <div className="additional-info">
        <h2>Our Strategies Save Our Clients Money & Increase Sales on Amazon.com</h2>
        <p>
          Our comprehensive experience on the Amazon Marketplace platform has helped many brands succeed in growing their market share against competitors. We analyze account performance and review fees that are negatively affecting your margins.
        </p>
        <p>
          Cutting costs, increasing traffic, featuring your products to more customers, and optimizing your catalog is just the start. We are a full service Amazon management agency that helps brands succeed.
        </p>
        
        <h3>Your success is THE only metric we measure our success, effectiveness, and efficiency upon</h3>
        <p>
          Dedicated to learning your goals; goals take many forms in business- our first step is to find what your goals are for your brand and products. From there, we offer a done for you solution to implement our combined strategies.
        </p>
        
        <h3>Committed to Proving Our Value</h3>
        <p>
          Consider us your partner, our goal is to grow your endeavor- if your business thrives- so does ours.
        </p>
      </div>
      
      <div className="contact-button-container">
        <button className="contact-us-button" onClick={() => setIsPopupOpen(true)}>Contact Us</button>
      </div>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup" onClick={() => setIsPopupOpen(false)}>&times;</button>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <input type="text" name="firstName" placeholder="First Name" onChange={handleInputChange} required />
              <input type="text" name="lastName" placeholder="Last Name" onChange={handleInputChange} required />
              <input type="text" name="companyName" placeholder="Company Name" onChange={handleInputChange} required />
              <input type="email" name="email" placeholder="Email" onChange={handleInputChange} required />
              <textarea name="businessDescription" placeholder="Tell me about your business" onChange={handleInputChange} required></textarea>
              
              <div className="help-options">
                <h3>Help Me With</h3>
                <label>
                  <input type="checkbox" name="accountManagement" onChange={handleInputChange} />
                  Amazon Account Management
                </label>
                <label>
                  <input type="checkbox" name="ppc" onChange={handleInputChange} />
                  Amazon PPC
                </label>
                <label>
                  <input type="checkbox" name="listingRanking" onChange={handleInputChange} />
                  Improve My Listing's Ranking
                </label>
                <label>
                  <input type="checkbox" name="accountAudit" onChange={handleInputChange} />
                  Audit My Amazon Account
                </label>
              </div>

              <select name="monthlySales" onChange={handleInputChange} required>
                <option value="">Monthly Amazon Sales</option>
                <option value="notSelling">Not currently selling on Amazon</option>
                <option value="0-15000">$0 to $15,000</option>
                <option value="15000-50000">$15,000 to $50,000</option>
                <option value="50000-100000">$50,000 to $100,000</option>
                <option value="100000-250000">$100,000 to $250,000</option>
                <option value="500000+">$500,000+</option>
              </select>

              <textarea name="comment" placeholder="Additional comments" onChange={handleInputChange}></textarea>

              <button type="submit" className="submit-button">Submit</button>
            </form>
          </div>
        </div>
      )}

      <footer className="footer">
        <div className="footer-content">
          <div className="social-media">
            <a href="https://www.facebook.com/marketplacemodel" className="social-button">
              <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
              </svg>
              Facebook
            </a>
            <a href="https://www.instagram.com/marketplacemodel" className="social-button">
              <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
              </svg>
              Instagram
            </a>
          </div>
          <div className="contact-info">
            <h3>Contact Us</h3>
            <p>Email: amazon@marketplacemodel.com</p>
            <p>Phone: 760 452 0707</p>
            <p>Address: Irvine, CA</p>
          </div>
        </div>
        <p className="copyright">© 2023 MarketplaceModel. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default ServicesPage;