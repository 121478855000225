import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Components/MM.jpg';

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="MarketplaceModel Logo" />
      </div>
      <nav className="menu">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/results">Results</Link></li>
          <li><Link to="/image-builder">Image Builder</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;